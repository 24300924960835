import React from 'react';
import Page from '../components/Page';
import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Sponsor from '../components/Sponsor';
import {Markdown} from '../components/Markdown';
import {graphql, Link} from 'gatsby';
import imgProjectsHeader from '../assets/images/header-rabbit-watering-carrots.png';
import Seo from '../components/seo';

export default function Projects({data: {projects, pageSettings}}) {


	return <Page orientation="portrait">

		<Seo remoteMetaData={projects} title={projects.titleSEO} />


		<div className="game-box">

			<div className="game-box-inner">
				<header className="header-area">
					<div className="container">
						<div className="header-grid">
							<div className="box-1">
							</div>
							<div className="box-2"/>

							<Menu/>
						</div>
					</div>
				</header>

				<div className="content" style={{paddingBottom: 91.3333}}>
					<div className="gen-page nav-grid">
						<div className="logo-box">
							<Link to="/">
								<Logo/>
							</Link>
						</div>
						<div className="button-box">
							<a href="https://shop.keenkids.com.au" target="_blank" className="kk-btn-rounded btn-primary3">Shop</a>
						</div>
					</div>

					<img src={imgProjectsHeader} alt="Rabbit watering carrots" />

					<Markdown>{projects.bodyText}</Markdown>

					<div className="button-box">
						<a href="/contact"  className="kk-btn-rounded btn-primary3">Contact us</a>
					</div>

					<Sponsor/>
				</div>
				{/* / .content */}
			</div>
			{/* / .game-box-inner */}
		</div>
		{/* / .game-box */}
	</Page>;
}

export const query = graphql`
    query {
		projects {
      		header
      		bodyText
			titleSEO
			descriptionSEO
			ogImage {
				localFile {
					publicURL
				}
			}
		}

    }
`;
